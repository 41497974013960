import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { IonFabButton, Platform } from '@ionic/angular';
import { StorageService } from './shared/utils/data-access/storage.service';
import { filter, forkJoin, take, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalAlertService } from './shared/utils/ui/alert/global-alert.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
 //@ViewChild('betaButton') betaButton: IonFabButton
  isHidden:boolean = false;
  constructor(private translate: TranslateService, private platform: Platform, private db: StorageService, private globaAlert: GlobalAlertService) {
    this.initializeApp();

    
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      event.returnValue = '';
      //alert("Chaca")
    });
  }

  initializeApp() {
    this.translate.setDefaultLang('de');
    forkJoin({ 
      db: this.db.storageReady().pipe(filter(val => val), take(1)),
      plt: this.platform.ready()
    }).subscribe(val => {
      console.log("ready", val)
      SplashScreen.hide();
      if(this.platform.is('desktop') === false){
        (<any>window).launchnavigator.setApiKey(environment.googleApiKey, (data) => console.log("success", data), (error) => console.log("error!", error))
      }
    })
  }

  /*
  onClickBetaButton(){
    this.isHidden = true;
    setTimeout(() => {
      this.isHidden = false;
    },10000)
  }
  */
}
